<template>
  <v-card width="400" class="mx-auto mt-5">
    <v-card-title>
      <h1 class="display-1">Login</h1>
    </v-card-title>
    <v-card-text>
      <v-alert dense v-if="error" type="error">{{ error }}</v-alert>
      <v-form @submit.prevent>
        <v-text-field
          label="Usuario"
          prepend-icon="mdi-account-circle"
          :rules="usuarioRules"
          v-model="username"
        >
        </v-text-field>
        <v-text-field
          label="Contraseña"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        >
        </v-text-field>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
            @click="sendLogin"
            ><v-icon left darf>mdi-login</v-icon>Ingresar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import RelojService from "@/services/RelojService";
import Helpers from "@/helpers/helpers.js";

export default {
  name: "LoginComponent",
  data() {
    return {
      showPassword: false,
      username: "",
      password: "",
      token: "",
      error: null,
      loading: false,
      usuarioRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          /\w+@\w+$/.test(v) ||
          "El nombre de usuario debe tener un formato válido.",
      ],
    };
  },
  methods: {
    sendLogin() {
      const fullUserName = this.username.split("@");
      this.loading = true;
      this.$store
        .dispatch("login", {
          username: fullUserName[0],
          password: this.password,
          tenant: fullUserName[1],
        })
        .then(() => this.$router.push({ name: "Ingreso" }))
        .catch((error) => {
          this.error = Helpers.handleError(error);
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
